<template>
    <div class="education container mx-auto px-4 py-8">
      <h1 class="text-3xl font-bold mb-6">Education & Certifications</h1>
      <div class="mb-8">
        <h2 class="text-2xl font-bold mb-2">Formal Education</h2>
        <div class="mb-4">
          <h3 class="text-xl font-semibold">Fullstack Academy Web Development Bootcamp</h3>
          <p class="text-gray-600">February 2023 - October 2023</p>
          <p>Software Engineering Certificate</p>
        </div>
        <div>
          <h3 class="text-xl font-semibold">Trinity Valley Community College</h3>
          <p class="text-gray-600">Graduated May 2011</p>
          <p>Associate of Arts in Music Business</p>
        </div>
      </div>
      <div>
        <h2 class="text-2xl font-bold mb-2">Certifications</h2>
        <ul class="list-disc list-inside">
          <li>Web Developer - Fullstack Academy</li>
          <li>LambdaTest Software Testing Professional Certificate - LambdaTest</li>
          <li>JavaScript (Basic) Certificate - HackerRank</li>
          <li>Frontend Developer (React) Certificate - HackerRank</li>
          <li>Software Engineer Certificate - HackerRank</li>
          <li>Google Ads Search Certification</li>
          <li>AI-Powered Performance Ads Certification</li>
          <li>Google Analytics Certification</li>
          <li>Lean Six Sigma Certification</li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'EducationView'
  }
  </script>